import '../App.css';

function Footer() {
    return(
    <footer>
          Imane Kimissi ©️ 2024
    </footer>
    );
}

export default Footer;